$(document).ready(function() {
  // this ensures that this js only runs if you're on the goal page
  if ($(".goal-panel").length === 0) {
    return;
  }

  // this is possibly an antipattern but it grabs the slug of the goal in
  // question from the goal panel. This way we only have to put it in one
  // place in the html and it's available to all the functions below that are
  // bound to element events
  var _slug = $(".goal-panel").data("slug");
  var _user = $(".goal-panel").data("username");

  // takes a js obj
  function svgLoaded_js(obj) {
    // for an svg w/object tag, test if the fallback is showing 
    var bb = obj.querySelector(".fallback").getBoundingClientRect()
    return bb.height === 0 && bb.width === 0 
  }

  // takes a jQuery obj
  function graphLoaded(obj) {
    if (obj.is("img")){
      // for an image, can test the object directly
      return obj[0].complete && obj[0].naturalHeight !== 0
    } else if (obj.is("object")) {
      // for an svg w/object tag, test if the fallback is showing 
      var bb = obj.find(".fallback")[0].getBoundingClientRect()
      return bb.height === 0 && bb.width === 0 
    }
  }

  // TODO GROUPGOALS: this is not fully true any longer.
  // groupies should also be present here, but how to test for that?
  function hasApiPermission() {
    return beemApi.currentUsername === _user || beemApi.tryanyway;
  }

  //Used for refreshing after a bad image load
  function refreshGraph()
  {
    //Getting the graph like this only works for the logged-in user's graphs.
    //and for admins fetching someone else's graph.
    if (!hasApiPermission())
    {
      return;
    }

    beetils.showInfinibee(_slug);
    beetils.pollUntilBeebrainUpdates(_user, _slug, function(data) {
      beetils.hideFlash();
      $(".refresh-stats").show();
      updateWithGoalData(data);
    }, function(data) {
      beetils.hideFlash();
      beetils.hideInfinibee(data.slug);
    });
  }

  var graphErrorTimeout;
  var checkGraphTimeout;

  // TODO: how can we schedule this when the dumb error event isn't firing?
  function onGraphError(event) {
    // Error handler for bad graphs.
    // Schedules a graph refresh shortly in the future in order to reduce chance
    // of slamming the backend.

    clearTimeout(graphErrorTimeout);
    graphErrorTimeout = setTimeout(refreshGraph, 5000);
  }

  // checkGraph() checks the passed graph object on the page for bad loads
  function checkGraph(obj)
  {
    if (!graphLoaded(obj)) {
      refreshGraph();
    }
  }

  // Delay calling this because if checkGraph runs before the page is 
  // fully loaded, the image looks !loaded and then we wind up erroneously
  // showing the user the "statistics are out of date" error msg.
  if (hasApiPermission())
  {
    var graphObject = $("#graph-image").length === 0 ? $("#graph-svg") : $("#graph-image");
    checkGraphTimeout = setTimeout(function() { 
      checkGraph(graphObject) }, 5000);

    // set up graph error callback 
    graphObject.on("error", onGraphError);
    // TODO: this is a big pita because Chrome doesn't fire the error event 
    // when the svg data fails. so we check for broken svg after every call to
    // updateSVG
  }

  $('.commitment_tab_link').click(function(){ $(".goal-bottom-tabs a[data-tab=commitment]").click()});

  $("#goal-box").hover(function(e){
    $("#goal-graph .octicon-link").toggle(e.type==="mouseenter")
  });


  // show infinibee if the goal is updating
  if ($(".goal-is-queued").length > 0) {
    beetils.showInfinibee(_slug);
    beetils.pollUntilBeebrainUpdates($(".goal-is-queued").data("username"), _slug, function(data) {
      updateWithGoalData(data);
    });
  }

  $("#svg-reload").click(function() { 
    window.location.reload()
  })

  function updateSVG_js(obj, url) {
    if (svgLoaded_js(obj)) {
      obj.setAttribute("data", url)
      obj.onload = function() {}
      obj.parentNode.replaceChild(obj,obj)
    } else {
    // if the svg has already failed to load, Chrome won't correctly reload
    // the object from source. which means we have to create a new element and 
    // add it to the DOM. If this turns out to cause noticable memory problems
    // or whatever, because who knows what's going on with garbage collection,
    // then we can add in a check of window.navigator.userAgent and look to see
    // if this is a Chrome browser, and only do this in that case?
      var reSVG = document.createElement("object");
      reSVG.setAttribute("type", "image/svg+xml");
      reSVG.setAttribute("data", url);
      reSVG.id = "graph-svg";
      reSVG.innerHTML = '<div class="fallback"><p>Whoops, something went wrong loading your graph!</p><p>Give us a second to try\'n fix that, or <a href="#" id="svg-reload" class="">Reload</a></p></div>'
      obj.parentNode.replaceChild(reSVG,obj)
    }
    clearTimeout(checkGraphTimeout);
    checkGraphTimeout = setTimeout(function() {
      checkGraph($("#graph-svg"))
    }, 3000);
  }
  function updateWithGoalData(data) {
    // could be a legacy png or could be an svg just update both
    $("#graph-image").attr("src", data.graph_url);
    if ($("#graph-svg").length > 0) {
      var ob = $("#graph-svg")[0]
      updateSVG_js(ob, data.svg_url)
      /*
      if (!svgLoaded_js(ob)) {
        // then the fallback is showing and we need to do stuffs
        console.log("fallback still showing!")
      } 
      */
    }

    /* here are updates we want to make to the page regardless of
     * who is watching at this point
     * N.B. other folks who aren't admins or groupies never see
     * the infinibee buzzing on someone else's graph
     */
    beetils.hideInfinibee(data.slug);
    $(".hero .doom").data("doom", data.losedate);
    updateDoom();

    $(".hero .baremin").data("baremin", data.baremin);
    $(".hero .baremin").data("baremintotal", data.baremintotal);
    updateBaremin();

    addDatapointToDataList(data.last_datapoint, data.slug);
    
    /* these updates are for the goal owner only (based on what stuff
     * they have access to in the goal interface; groupies & admins 
     * don't see this stuff relating to settings etc
     */
    if (beemApi.currentUsername === _user) {
      updateSafeBufForm(data); // non-groupo
      updateRoadEditor(data);  // non-groupo
      updateCurVal(data);      // non-groupo
    }
    /* if there's a refresh icon visible, we want the color of that to update too. we want this to happen for anyone who is shown that icon, which could be more than just the goal owner */
    if ($(".add-data.autod .sync .refresh").length > 0) {
      updateDoomColor($(".add-data.autod .sync .refresh"), data);
    }
  }

  function updateRoadEditor(data) {
    if ($("form.road-editor").length === 0) { return; }
    if (data.goaldate) {
      var goaldate = new moment(data.goaldate*1000);
      $("form.road-editor #goal_tfin").val(goaldate.format("YYYY-MM-DD"));
    } else {
      $("form.road-editor #goal_tfin").removeAttr("value");
    }
    $("form.road-editor #goal_rfin").val(data.rate);
    $("form.road-editor #goal_vfin").val(data.goalval);

    var tini = new moment(data.initday*1000);
    $("form.road-editor #goal_tini").val(tini.format("YYYY-MM-DD"));
    $("form.road-editor #goal_vini").val(data.initval);
  }

  function htmlForDatapoint(datapoint, slug) {
    // If you change something here, it probably should match in _datapoint.html.erb
    var datapoint_div = $("<div></div>", {
      title: datapoint.fulltext,
      class: "data-row row",
      "data-id" : datapoint.id,
      "data-value": datapoint.value,
      "data-date": datapoint.daystamp,
      "data-comment": datapoint.comment,
      "data-canonical": datapoint.canonical,
      "data-entered": datapoint.id
    });

    var date_div = $("<div></div>", {
      class: "col-6 col-md-2"
    }).append($("<input />", {
      type: "text",
      class: "date",
      value: datapoint.daystamp.slice(0, 4) + "-" +
        datapoint.daystamp.slice(4, 6) + "-" +
        datapoint.daystamp.slice(6, 8)
    }));

    var value_div = $("<div></div>", {
      class: "col-6 col-md-1"
    }).append($("<input />", {
      type: "text",
      class: "value",
      value: datapoint.value
    }));

    var comment_div = $("<div></div>", {
      class: "col-12 col-md-5"
    }).append($("<input />", {
      type: "text",
      class: "comment",
      value: datapoint.comment
    }));

    var update_div = $("<div></div>", {
      class: "col-6 col-md-2"
    }).append($("<button></button>", {
      type: "button",
      class: "bbtn update-datapoint",
      "data-slug": slug,
      "data-id": datapoint.id
    }).text("Update"));

    var delete_div = $("<div></div>", {
      class: "col-6 col-md-2"
    }).append($("<button></button>", {
      type: "button",
      class: "bbtn delete-datapoint",
      "data-slug": slug,
      "data-id": datapoint.id
    }).text("Delete"));

    return datapoint_div.append(date_div, value_div, comment_div, update_div, delete_div);
  }

  function updateSafeBufForm(data) {
    if ($("#max-safety").length == 0) {
      return 
    }
    if (data.dir * data.yaw < 0) { // update in terms of units of buffer
      $(".rr-buf").text(data.baremin)
      var newval = Math.abs(data.delta)
      $(".ratchet-form").data("upperlimit", newval);
      $(".ratchet-form").data("lowerlimit", 0);
    } else {
      var newValue = Math.max(data.safebuf - 1, 0);
      // update limits for the stepper
      $(".safebuf-form .stepper-control .stepper").data("limit-plus", newValue);
      $(".safebuf-form .stepper-control .stepper").data("limit-minus", 0);
      $(".safebuf-form .stepper-control .stepper-value").val(newValue);
      // update limits for the form validations
      $(".ratchet-form").data("upperlimit", newValue);
      $(".ratchet-form").data("lowerlimit", 0);
      var divs = $(".ratchet-form .form-row");
      var enoughexp = /I don\'t have enough info to ratchet/
      // if we've just restarted the goal we don't have enough info to ratchet
      // don't write over that.
      if ( enoughexp.test(divs.html()) ) {
        return
      }
      // now, check if we're on flat and need to change the form
      if (data.delta === 0 && data.safebuf >= 100) { 
        // this is a heuristic check for "infinite flat". 
        var rrbuf = $(".rr-buf").detach()
        rrbuf.text(data.safebuf)
        var row1 = $("<div></div>", {class: "form-row alert alert-warning"})
          .prepend($("<span></span>", {class: "octicon octicon-alert"}))
          .prepend("This flat spot goes oooonnnn... ratchet's not really the right way to change that. You probably want to change your rate (in the \"Commitment Dial\" section just above this one).")
        var row2 = $("<div></div>", {class: "form-row"})
          .append(" This will reduce the length of your ")
          .append(rrbuf)
          .append(" break.")
        divs[0].remove()
        divs[1].remove()
        $(".ratchet-form").prepend(row2).prepend(row1)
      } else if (data.delta === 0 && data.safebuf > 1) { 
        // this is a heuristic check for "are we flat". it might not
        // always be right... but how else can you have a delta of 0
        // (i.e. be exactly on the redline) and still have a safety buffer
        // that's greater than 1?)
        $(".ratchet-form input[type='submit']").val("⚡️Ratchet⚡️") 
        var rrbuf = $(".rr-buf").detach()
        rrbuf.text(data.safebuf)
        var row1 = $("<div></div>", {class: "form-row"})
          .prepend("You are on "+beetils.aoran(data.safebuf)+" ")
          .append(rrbuf)
          .append(" day flat spot.")
        var row2 = $("<div></div>", {class: "form-row alert alert-warning"})
          .prepend($("<span></span>", {class: "octicon octicon-alert"}))
          .append(" This will reduce the length of your break.")
        divs[0].remove()
        divs[1].remove()
        $(".ratchet-form").prepend(row2).prepend(row1)
      } else {
        $(".ratchet-form input[type='submit']").val("Ratchet") 
        var rrbuf = $(".rr-buf").detach()
        rrbuf.text(beetils.splur(data.safebuf, "day"));
        var row1 = $("<div></div>", {class: "form-row"})
          .prepend("You have ")
          .append(rrbuf)
          .append(" until you derail on this goal.")
        var row2 = $("<div></div>", {class: "form-row"})
          .prepend("This reduces the number of days until you derail. If you want today to be a beemergency, for example, enter 0.")
        divs[0].remove()
        divs[1].remove()
        $(".ratchet-form").prepend(row2).prepend(row1)
      }
    }
  }

  function updateCurVal(data) {
    $(".rate-form .curval").data("curval", data.curval);
  }

  function addDatapointToDataList(data, slug) {
    var dtable = $("#datapoints-table .table-body");
    var drow = dtable.find(".data-row[data-id="+data.id+"]");
    if (drow.length === 0) {
      dtable.prepend(htmlForDatapoint(data, slug));
      dtable.find(".data-row:first input.comment").val(data.comment);
      dtable.find(".data-row:first").data("comment", data.comment);
    } else { //no new datapoint
      drow.data("comment", data.comment);
      drow.find("input.comment").val(data.comment);
      drow.find("input.value").val(data.value);
      //alert("no new datapoints found");
    }
    bindUpdateDatapointButtons();
    bindDeleteDatapointButtons();
  }

  $(".add-data-form input, .oldbee textarea").keydown(function(e) {
    if(e.keyCode == 13 && e.metaKey ||
       e.keyCode == 13 && e.ctrlKey) {
      $(this).form().submit();
    }
  });

  $(".add-data-form").submit(function(e) {
    e.preventDefault();
    $(this).find("input[type=submit]").prop("disabled",true);
    if ($("form.add-data-form input.stepper-value").val().length === 0) {
      $("form.add-data-form input.stepper-value").addClass("error");
      $(this).find("input[type=submit]").prop("disabled",false);
      return false;
    } else {
      $("form.add-data-form input.stepper-value").removeClass("error");
    }

    var dayOfMonth = $("form.add-data-form .add-data-date").val();

    var urtext = dayOfMonth + " " +
      $("form.add-data-form input.stepper-value").val();

    if ($("form.add-data-form input[name='comment']").val().length > 0) {
      urtext += " \"" + $("form.add-data-form input[name='comment']").val() + "\"";
    }

    var slug = $("form.add-data-form input[name='slug']").val();
    beemApi.addDatapoint($("form.add-data-form input[name='slug']").val(), {
        urtext: urtext
      },
      function(data) {
        beetils.showInfinibee(slug);
        beetils.showFlash("success", "Datapoint added! Refreshing your graph...", true);
        addDatapointToDataList(data, slug);
        $(".add-data input[name='comment']").attr("placeholder", data.comment.length > 0 ? data.comment : "Optional comment");
        $(".add-data input[name='comment']").prop("value", "");
        $(".add-data input.stepper-value").prop("value", "");
        $(".add-data-form input[type=submit]").prop("disabled",false);
        beetils.pollUntilBeebrainUpdates("me", slug, function(data) {
          beetils.hideFlash();
          $(".refresh-stats").show();
          updateWithGoalData(data);
          beetils.showFlash("success", "Graph updated!");
        }, function(data) {
          beetils.hideFlash();
          beetils.hideInfinibee(data.slug);
          beetils.showFlash("error", "There was a problem refreshing your graph");
        });
      },
      function(error) {
        $(".add-data-form input[type=submit]").prop("disabled",false);
        alert("There was a problem adding your datapoint");
      }
    );
  });

  $(".refresh-stats").click(function() {
    window.location.reload();
  })

  $("form.add-data-form input.stepper-value").on("keyup", function() {
    $(this).removeClass("error");
  });

  //Handling bottom tabs and bottom anchors -----
  //
  // Concept:
  // Tabs aren't anchor links, so they don't scroll you.
  // "Anchor links in your URL do scroll you"

  // Please note, we are not actually using Bootstrap tabs here!

  // When bottom tabs get clicked, select them and scroll to them
  $('.btabs a[role="tab"]').on("click", function(e) {
      const tabName = $(this).data("tab")
      const newUrl = window.location.href.split("#")[0] + "#" + tabName;
      e.preventDefault();

      history.replaceState(null, null, newUrl); // Update the address bar, without adding to history
      handleAnchorLink("#" + tabName, false);
      return false;
  });

  function openBottomTab(tabName) {
    //A tabName is like "commitment" rather than commitment-tab or #commitment

    const tabpanes = $(".goal-bottom-tabs .tab-pane");
    if ($('.goal-bottom-tabs .tab-pane#' + tabName + "-tab").length === 0)
    {
        console.error("Did not find tab pane corresponding to tabname " + tabName);
        return;
    }
    //save to local storage so the correct tab automatically opens next time

    localStorage.setItem("com.beeminder.goals." + _slug + ".bottomTab", tabName);

    $(".goal-bottom-tabs .btabs li").removeClass("active").prop('aria-selected', false);
    $(".goal-bottom-tabs .btabs li [data-tab='" + tabName + "']").parent().addClass("active");
    tabpanes.hide().removeClass("active").hide();
    $(".goal-bottom-tabs #" + tabName + "-tab").addClass("active").show();
    $('.goal-bottom-tabs .btabs li').prop('aria-selected', true);
  }

  function scrollToPosition(position)
  {
    $('html, body').animate({
        scrollTop: position,
    }, 200);
  }

  function handleAnchorLink(hash, scrollTo) {
    // This assumes that the pathname and protocol and host are the same, and
    // all we're doing is going to an anchor link on this page.

    //Our anchor link is either to
    // * a bottom tab
    // * an item inside a bottom tabpanel
    // * to an item outside of a bottom tabpanel
    // * invalid/nonexistent

    //If we are scrolling to it:
    //if the anchor is a whole tab, show the whole tablist.
    //if the anchor is inside a tabpanel, open that tabpanel, scroll directly to that item.

    if (hash.startsWith("#")) {

      const tabName = hash.substr(1);

      if ($('.btabs a[data-tab="' + tabName + '"]').length) { //is it to a whole bottom tab?
        openBottomTab(tabName);
        if (scrollTo) {
          scrollToPosition($(".goal-bottom-tabs").offset().top); // scroll to the whole tablist
        }
        return;
        } else {
          const hashResults = $('.goal-settings').find(hash); // is it to an element in the bottom tab panels?
          if (hashResults.length) {
            //find which tab it's in so we can open it
            const ancestorTabPanels = hashResults.first().closest("[role='tabpanel']");
            if (!(ancestorTabPanels.length))
            {
              window.TrackJS && TrackJS.console.log("Did not find any ancestor tab panels for anchor link: " + hash);
              return;
            }

            const tabName = ancestorTabPanels.first().attr('id').replace("-tab", "");
            openBottomTab(tabName);

            if (scrollTo) {
              scrollToPosition(hashResults.first().offset().top); // open directly to the item
            }
            return;
        } else {
            // They probably wanted to go to something that wasn't in the bottom tabs.
            window.TrackJS && TrackJS.console.log("User wanted non-bottom tab anchor link: " + hash);
        }
      }
    }
  }

  function handleAnchorLinkOnLoad() {
    // If there is an anchor link in the address, use that
    // if not, see if local storage has one.

    var hash = window.location.hash;
    var scrollTo = true;

    if (hash && (hash === "#newbee-entry" || hash === "#advanced-entry")) {
      // These are not real anchors, but are used to open the page to a particular
      // data entry state.
    } else if (hash.length === 0) {
      scrollTo = false;
      hash = localStorage.getItem("com.beeminder.goals." + _slug + ".bottomTab");
      if (hash) {
        hash = "#" + hash;
      }
    }



    if (hash) {
      handleAnchorLink(hash, scrollTo);
    }
  }

  handleAnchorLinkOnLoad();

  //Done Handling bottom tabs and bottom anchors -----

  $(".add-data .advanced-entry a").click(function(e) {
    e.preventDefault();
    if (dataEntry() === "newbee") {
      setDataEntry("advanced");
    } else {
      setDataEntry("newbee");
    }
    toggleDataEntry();
  });

  // precedence location.hash > localstorage > newbee
  function dataEntry() {
    var hash = location.hash.replace("#", "");
    var stored = localStorage.getItem("com.beeminder.goals." + _slug + ".dataEntry");
    if (hash === "newbee-entry" || hash === "advanced-entry") {
      return hash.replace("-entry","");
    }
    if (stored) { return stored; }
    return "newbee";
  }

  function setDataEntry(dataEntry) {
    localStorage.setItem("com.beeminder.goals." + _slug + ".dataEntry", dataEntry);
  }

  function toggleDataEntry() {
    if (dataEntry() === "newbee") {
      $(".add-data form.oldbee").addClass("hidden");
      $(".add-data form.newbee").removeClass("hidden");
      $(".add-data .advanced-entry a").text("Advanced Entry");
      $(".add-data .stepper-value").focus();
    } else {
      $(".add-data form.oldbee").removeClass("hidden");
      $(".add-data form.newbee").addClass("hidden");
      $(".add-data .advanced-entry a").text("Form Entry");
      $(".add-data textarea").focus();
    }
  }

  toggleDataEntry();

  /* so-called ROAD DIAL */
  /* RATE-FORM CHANGING RUNITS */
  $(".rate-form select[name='runits']").change(function(e) {
    var previous = $(this).data("previous");
    if (previous === $(this).val()) {
      return;
    }
    $(this).data("previous", $(this).val());
    var formRate = $(".rate-form .stepper-value").val();
    // special case for 0-rate
    if (formRate === 0 || formRate === "0") {
      var newRate = 0;
    } else {
      var prevRatePerMillisecond = beetils.ratePerMillisecond(formRate, previous);
      var newRatePerMillisecond = beetils.ratePerMillisecond(formRate, $(this).val());
      var newRate = Math.round(100*formRate*(prevRatePerMillisecond/newRatePerMillisecond))/100;
    }
    $(".rate-form .stepper-value").val(newRate);
    $(".rate-form .stepper-value").hide().fadeIn(1000)
  });

  /* RATE-FORM SUBMIT */
  $(".rate-form form button.update-rate").click(function(e) {
    var form = $(".rate-form form");
    if (form.find(".form-row.goaldate:not(.disabled)").length > 0) {
      var goaldate = new moment(form.find("input[name='goaldate']").val(), "YYYY-MM-DD");
      var endOfDays = new moment("2099-12-31", "YYYY-MM-DD");
      /* TODO: put error checking here */
      if (goaldate.isAfter(endOfDays)) {
        alert("Goal date is too far in the future! Try something before 2099.");
        e.stopPropagation();
        return false;
      }
    }
  });

  $(".rate-form form").submit(function(e) {
    e.preventDefault();
    var form = $(".rate-form form");
    var params = $.extend(beetils.tokenParams(), {
      "runits": form.find("select[name='runits']").val(),
    });

    if (form.find(".form-row.goalrate:not(.disabled)").length > 0) {
      params = $.extend(params, {
        "rate": form.find(".stepper-value").val()
      });
    }
    if (form.find(".form-row.goaldate:not(.disabled)").length > 0) {
      var goaldate = new moment(form.find("input[name='goaldate']").val(), "YYYY-MM-DD");
      params = $.extend(params, {
        "goaldate": goaldate.format('X') // unix seconds, not milliseconds
      });
    }
    if (form.find(".form-row.goalval:not(.disabled)").length > 0) {
      params = $.extend(params, {
        "goalval": form.find("input[name='goalval']").val()
      });
    }
    $.ajax({
      type: "POST",
      url: "/api/v1/users/me/goals/" + form.data("slug") + "/dial_road.json",
      data: params,
      success: function(data) {
        $("#current-runits").data("runits", data.runits);
        beetils.showInfinibee(data.slug);
        beetils.showFlash("success", "Successfully updated your commitment. Refreshing your graph...", true);
        beetils.pollUntilBeebrainUpdates("me", data.slug, function(data) {
          beetils.hideFlash();
          $(".refresh-stats").show();
          updateWithGoalData(data);
          beetils.showFlash("success", "Graph updated!");
          form.find(":submit").removeAttr("disabled");
        }, function(data) { /* TODO: error handler for what? */
          beetils.hideFlash();
          beetils.hideInfinibee(data.slug);
          //console.log(data)
          beetils.showFlash("error", "There was a problem refreshing your graph");
          form.find(":submit").removeAttr("disabled");
        });
      },
      error: function(jxhr, status, err) {
        error_obj = JSON.parse(jxhr.responseText) 
        error_message = "Oops! There was an error updating your commitment."
        if (error_obj.errors.rfin !== undefined) {
          error_message = "Oops! that rate is not a number. Please enter a number."
        }
        else {
          console.log(error_obj)
        }
        beetils.showFlash("error", error_message);
        form.find(":submit").removeAttr("disabled");
      }
    });
  });
  // end rate form

  // bind fetch autodata button
  $(".goal-panel .refresh").click(function(event) {
    if ($(this).hasClass("manual")) { return true; } // for ifttt and zapier goals
    event.preventDefault();
    var slug = $(this).data("slug");
    beetils.showInfinibee(slug);
    $.ajax({
      type: "GET",
      url: "/api/v1/users/me/goals/" + slug + "/refresh_graph.json",
      data: beetils.tokenParams(),
      success: function(data) {
        beetils.showFlash("notice", "Fetching your data...");
        beetils.pollUntilBeebrainUpdates("me", slug, function(data) {
          beetils.hideFlash();
          $(".refresh-stats").show();
          updateWithGoalData(data);
          beetils.showFlash("success", "Graph updated!");
        }, function(data) {
          beetils.showFlash("error", "There was a problem refreshing your graph");
        });
      },
      error: function(error) {
        alert("There was a problem refreshing your goal");
      }
    });
    return false;
  });
  function updateDoomColor(obj, data) {
    obj.removeClass("blue orange red green dkgreen gray")
      .addClass(beetils.doomColorClass(data.losedate, data.coasting))
  }

  function bindUpdateDatapointButtons() {
    $("#data-tab .update-datapoint").hide();
    $("#data-tab .editable .update-datapoint").show();
    $("#data-tab .editable .update-datapoint").unbind("click");
    $("#data-tab .editable .update-datapoint").click(function(e) {
      e.preventDefault();
      var row = $("#data-tab .data-row[data-id='" + $(this).data("id") + "']");
      var params = $.extend(beetils.tokenParams(), {
        "_method": "PUT",
        "daystamp": row.find(".date").val(),
        "value": row.find(".value").val(),
        "comment": row.find(".comment").val()
      });
      var slug = $(this).data("slug");
      $.ajax({
        type: "POST",
        url: "/api/v1/users/me/goals/" + slug + "/datapoints/" +
          $(this).data("id") + ".json",
        data: params,
        success: function(data) {
          beetils.showFlash("success", "Datapoint updated. Refreshing your graph...", true);
          beetils.showInfinibee(slug);
          beetils.pollUntilBeebrainUpdates("me", slug, function(data) {
            beetils.hideFlash();
            updateWithGoalData(data);
            beetils.showFlash("success", "Graph updated!");
          }, function(data) {
            beetils.hideFlash();
            beetils.hideInfinibee(slug);
            beetils.showFlash("error", "There was a problem updating your graph");
          });
        },
        error: function(data) {
          beetils.showFlash("error", "Could not update that datapoint");
        }
      })
    });
  }

  bindUpdateDatapointButtons();

  function bindDeleteDatapointButtons() {
    $("#data-tab .delete-datapoint").hide();
    $("#data-tab .editable .delete-datapoint").show();
    $("#data-tab .editable .delete-datapoint").unbind("click");
    $("#data-tab .editable .delete-datapoint").click(function(e) {
      e.preventDefault();
      var drow = $(".data-row[data-id="+$(this).data("id")+"]")
      if (!confirm("WARNING: Deleting data can cause your goal to insta-derail!!\nDeleting ["+drow.data("canonical")+"] Are you sure?")) {
        return;
      }
      var params = $.extend(beetils.tokenParams(), {
        "_method": "DELETE"
      });
      var slug = $(this).data("slug");
      var datapointId = $(this).data("id");
      $.ajax({
        type: "POST",
        url: "/api/v1/users/me/goals/" + slug + "/datapoints/" +
          datapointId + ".json",
        data: params,
        success: function(data) {
          $("#data-tab .data-row[data-id='" + datapointId + "']").fadeOut();
          beetils.showFlash("success", "Datapoint deleted. Refreshing your graph...", true);
          beetils.showInfinibee(slug);
          beetils.pollUntilBeebrainUpdates("me", slug, function(data) {
            beetils.hideFlash();
            updateWithGoalData(data);
            beetils.showFlash("success", "Graph updated!");
          }, function(data) {
            beetils.hideFlash();
            beetils.hideInfinibee(slug);
            beetils.showFlash("error", "There was a problem updating your graph");
          });
        },
        error: function(data) {
          beetils.showFlash("error", "Could not delete that datapoint");
        }
      })
    });
  }
  bindDeleteDatapointButtons();

  function getSortOrder(slug, by) {
    var so = localStorage.getItem("com.beeminder.sortDatapoints." + slug + ".sortOrder."+by);
    return so || "desc";
  }

  function getSortKey(slug) {
    var sb = localStorage.getItem("com.beeminder.sortDatapoints." + slug + ".sortBy");
    return sb || "entered";
  }

  function storeSort(slug, sortkey, sortorder) {
    localStorage.setItem("com.beeminder.sortDatapoints." + slug + ".sortOrder." +sortkey, sortorder);
    localStorage.setItem("com.beeminder.sortDatapoints." + slug + ".sortBy", sortkey);
  }

  function sortDatapoints(slug, sortkey, sortorder) {
    $("#datapoints-table .data-row").sort(function(a, b) {
      var aVal = $(a).data(sortkey);
      var bVal = $(b).data(sortkey);

      if (sortkey === "value" || sortkey === "date") {
        aVal = parseFloat(aVal);
        bVal = parseFloat(bVal);
      }

      if (sortorder === "asc") {
        return aVal > bVal ? 1 : -1;
      } else {
        return aVal > bVal ? -1 : 1;
      }
    }).appendTo($("#datapoints-table .table-body"));

    storeSort(slug, sortkey, sortorder);
    $("#datapoints-table .header-row .sort-arrow").removeClass("octicon-triangle-up");
    $("#datapoints-table .header-row .sort-arrow").removeClass("octicon-triangle-down");
    $("#datapoints-table .header-row .sort-arrow[data-sort-string='" + sortkey + "']").addClass(sortorder === "asc" ? "octicon-triangle-up" : "octicon-triangle-down");
  };

  $("#datapoints-table .header-row .sort-button").click(function(e) {
    var slug = $(this).data("slug");
    var sortKey = $(this).data("sort-string");
    var curKey = getSortKey(slug);
    var order = getSortOrder(slug, sortKey);
    if (curKey == sortKey) {
      sortDatapoints(slug, sortKey, order === "asc" ? "desc" : "asc");
    } else {
      sortDatapoints(slug, sortKey, order);
    }
    e.preventDefault();
  });
 
  sortDatapoints(_slug, getSortKey(_slug), getSortOrder(_slug, getSortKey(_slug)))

  $("#data-tab .load-all-datapoints").click(function(e) {
    e.preventDefault();
    beetils.showFlash("notice", "Fetching more datapoints...");
    var moredata = $("#data-tab button.load-all-datapoints");
    $(".load-all-datapoints").prop("disabled", true);
    var slug = $(this).data("slug");
    var _this = $(this);
    var page = $(this).data("page");
    var pageParams = (page === "all" ? {} : { page: page+1 });
    $.ajax({
      type: "GET",
      url: "/api/v1/users/me/goals/" + slug +"/datapoints.json",
      data: $.extend(beetils.tokenParams(), pageParams),
      success: function(data) {
        beetils.hideFlash();
        beetils.showFlash("success", "Success!");
        var is_all_data;
        if (page === "all") {
          $("#datapoints-table .table-body").empty();
          is_all_data = true;
        } 
        else { 
          is_all_data = page*_this.data("pagesz") >= _this.data("numpts");
          _this.data("page", page+1);
        }
        if (is_all_data) {
          moredata.text("All datapoints loaded");
          $("a.load-all-datapoints").hide();
        } 
        else {
          $(".load-all-datapoints").prop("disabled", false);
        }
        data.forEach(function(datapoint) {
          var row = htmlForDatapoint(datapoint, slug);
          $("#datapoints-table .table-body").append($(row));
          $("#datapoints-table .table-body .data-row:last .comment").val(datapoint.comment);
          $("#datapoints-table .table-body .data-row:last").data("comment", datapoint.comment);
        });
        bindDeleteDatapointButtons();
        bindUpdateDatapointButtons();
        sortDatapoints(slug, getSortKey(slug), getSortOrder(slug, getSortKey(slug)));
      },
      error: function(data) {
        beetils.hideFlash();
        $(this).removeAttr("disabled");
        beetils.showFlash("error", "Something went wrong when fetching your datapoints");
      }
    })
  });

  function doomFormat() {
    return localStorage.getItem("com.beeminder.goals." + _slug + ".doomFormat");
  }

  function setDoomFormat(format) {
    localStorage.setItem("com.beeminder.goals." + _slug + ".doomFormat", format);
  }

  if (!doomFormat()) {
    setDoomFormat("countdown");
  }

  // refactored this to move it into beetils so it can be used in other
  // contexts, like for the dashboard GATEWAY EXPERIMENT; DRY things up
  // etc. But the "coasting" check seems specific to the goal page, so I'm
  // leaving this wrapper around beetils.doomColorClass, where the
  // original function definition was. 
  // 
  // This matters when a goal is "coasting" and the tfin is less than a
  // week away.
  //
  // Without this check I believe the goal page would display the countdown
  // as Green => Blue => Orange => Red progressively, as the tfin approached
  // but with this check, we continue showing it as green during that
  // period due because it is "coasting" (i.e. has a greater kyoom total
  // than the red line's value at tfin)
  function doomColorClassOrCoasting(doomstamp) {
    if ($(".goal-panel").data("coasting")) {
      return "green";
    } else {
      return beetils.doomColorClass(doomstamp);
    }
  }

  function updateDoom() {
    $(".hero .doom").text(
      beetils.doomString(
        $(".hero .doom").data("doom"), 
        doomFormat(),
        $(".hero .doom").data("tz")
      ));
    const phat = $(".goal-panel").data("yaw") === -1 && $(".goal-panel").data("dir") === -1;
    $(".hero .doom-modifier").text(
      beetils.doomModifier(
        _slug, 
        $(".goal-panel").data("limiter") || phat, 
        $(".goal-panel").data("coasting")
      ));
    $(".hero .doom").removeClass("red orange blue green dkgreen gray");
    $(".hero .doom").addClass(
      beetils.doomColorClass(
        $(".hero .doom").data("doom"), $(".goal-panel").data("coasting")
      )
    );
  }

  window.setInterval(function() {
    updateDoom();
  }, 1000);

  function checkDeadlineDay() {
    var udt = $(".doom").data("deadtime")
    var now = moment()
    var day = moment.unix(udt)
    if ($(".goal-panel").data("archived")) {
      return;
    } else if (day < now) {
      var clicky = $("<a></a>", {
        class: "bbtn",
      }).text("Refresh Page").click(function(){window.location.reload()})
      var msg = $("<span></span>").text("A new day has dawned – ").append(clicky)
      beetils.showFlash("notice", msg, true)
      $(".doom").data("deadtime", udt+beetils.SID)
    } else {
      //console.log("not noticing: NOW["+now.format()+"] THEN["+day.format()+"]")
    }      
  }
  window.setInterval(function() {
    checkDeadlineDay();
  }, 1000);

  $(".hero .doom").click(function(e) {
    e.preventDefault();
    if (doomFormat() === "calendar") {
      setDoomFormat("countdown");
    } else {
      setDoomFormat("calendar");
    }
    updateDoom();
  });

  // update deadline tooltip if browser tz does not match usertz 
  (function() {
    var usertz = $(".hero .doom").data("tz");
    var browsertz = moment.tz.guess();
    if (usertz != browsertz) {
      var deadtime = $(".hero .doom").data("deadtime");
      var browserdead = moment.tz(deadtime*1000, browsertz); //.format("HH:mm ");
      var tzstr = ''
      if (browserdead.format('z').match(/^[A-Z]+$/)){
        tzstr = browserdead.format(' z')
      } else {
        tzstr = " "+moment.tz.guess().replace(/^.*\//, '')
          .replace(/_/g, ' ')
      }
      tzstr += browserdead.format(' (UTCZ)').replace(/:00/,'')
      $(".hero .doom").attr("title", 
        $(".hero .doom").attr("title")
        + " / "
        + browserdead.format("HH:mm")
        + tzstr
      ).tooltip();
    } else {
      $(".hero .doom").tooltip();
    }
  })();

  function updateBaremin() {
    var yaw = $(".goal-panel").data("yaw");
    var dir = $(".goal-panel").data("dir");
    var append_delta, append_abs;
    if (yaw === -1 && dir === 1) { // WEEN 
      append_delta = "";
      append_abs = " total";
    }
    else if (yaw === 1 && dir === -1) { // RASH 
      append_delta = "";
      append_abs = " total";
    }
    else if (yaw === -1 && dir === -1) { // PHAT 
      append_delta = " today";
      append_abs = " today";
    }
    else if (yaw === 1 && dir === 1) { // MOAR
      append_delta = "";
      append_abs = " total";
    }

    var text;
    if (beetils.bareminFormat(_slug) === "delta") {
      text = $(".hero .baremin").data("baremin");
      if (typeof(text) === "number") {
        text = parseFloat(text);
        if (text > 0) { text = "+" + text; }
      } else if (typeof(text) === "string" && !text.match(/^\+/)) {
        // handle when bare min is in HH:MM format
        var hour = parseFloat(text.split(":")[0])
        if (hour >= 0 && text[0] !== "-") { text = "+" + text; }
      }
      text += append_delta;
    } else {
      text = $(".hero .baremin").data("baremintotal");
      if (typeof(text) === "number") {
        text = parseFloat(text);
        text = text + append_abs; 
      } else if (typeof(text) === "string" && !text.match(/^\+/)) {
        // handle when bare min is in HH:MM format
        var hour = parseFloat(text.split(":")[0])
        if (hour >= 0) { text = text + append_abs; }
      } else {
        text = text + append_abs;
      }
    }

    const phat = yaw === -1 && dir === -1;
    if ($(".goal-panel").data("limiter") || phat) {
      text = "limit " + text;
    }

    $(".hero .baremin").html(text);
  }

  updateBaremin();

  $(".hero .baremin").click(function(e) {
    e.preventDefault();
    if (beetils.bareminFormat(_slug) === "delta") {
      beetils.setBareminFormat(_slug, "total");
    } else {
      beetils.setBareminFormat(_slug, "delta");
    }
    updateBaremin();
  });

  if ($("#pledge-modal").length > 0 || $(".pledge-setting").length > 0) {
  // bind stepdown button
  $(".pledge-setting .stepdown").click(function(e) {
    e.preventDefault();
    var _this = $(this);
    $.ajax({
      type: "POST",
      url: "/api/v1/users/me/goals/" + _this.data("slug") + "/stepdown.json",
      data: beetils.tokenParams(),
      success: function(data) {
        $("#pledge-modal").modal('hide');
        beetils.showFlash("success", "Your pledge will decrease in 7 days. Updating...");
        window.setTimeout(function() {
          window.location.reload();
        }, 1000);
      },
      error: function(data) {
        alert("There was a problem decreasing your pledge.");
      }
    });
  });

  // bind cancel stepdown button
  $(".pledge-setting .cancel-stepdown").click(function(e) {
    e.preventDefault();
    var _this = $(this);
    $.ajax({
      type: "POST",
      url: "/api/v1/users/me/goals/" + _this.data("slug") + "/cancel_stepdown.json",
      data: beetils.tokenParams(),
      success: function(data) {
        $("#pledge-modal").modal('hide');
        beetils.showFlash("success", "Your pledge will stay the same. Updating...");
        window.setTimeout(function() {
          window.location.reload();
        }, 1000);
      },
      error: function(data) {
        alert("There was a problem canceling your pledge decrease.");
      }
    });
  });

  // bind pledge increase button
  $(".pledge-setting .shortcircuit").click(function(e) {
    e.preventDefault();
    $.ajax({
      type: "POST",
      url: "/api/v1/users/me/goals/" + $(this).data("slug") + "/shortcircuit.json",
      data: beetils.tokenParams(),
      success: function(data) {
        $("#pledge-modal").modal('hide');
        beetils.showFlash("success", "Your pledge has been increased. Updating...");
        window.setTimeout(function() {
          window.location.reload();
        }, 1000);
      },
      error: function(data) {
        alert("There was a problem increasing your pledge.");
      }
    });
  });
  }

  //-- begin road editor --//

  // only bind the non-admin road editor's submit event. the admin editor
  // submits as part of a larger form.
  // This was causing a bug where we'd double submit the form 
  // changing this to bind more specifically to .goal-settings .road-editor
  // which will also not submit the admin form 
  $(".goal-settings form.road-editor").submit(function(e) {
    e.preventDefault()
    $(this).find("input[type='submit']").prop("disabled")
    _this = $(this);
    // jsonify three values into an array
    var jsonrow = function(t,v,r) {
      return "["+(t?("\""+t+"\""):"null")+","+(v?v:"null")+","+(r?r:"null")+"]"
    }
    var params = beetils.tokenParams()
    var roadstr = $(this).find("#goal_road_string").val().slice(1,-1)
    var roadall = "[" +
      jsonrow($(this).find("#goal_tini").val(), $(this).find("#goal_vini").val(), null)+","+
      (roadstr.length ? roadstr+"," : "") +
      jsonrow($(this).find("#goal_tfin").val(), $(this).find("#goal_vfin").val(), $(this).find("#goal_rfin").val()) + "]";

    params = $.extend(params, {
      "_method": "PUT",
      roadall: roadall
    })
    $.ajax({
      type: "POST",
      url: "/api/v1/users/me/goals/" + _slug + ".json",
      data: params,
      success: function(data) {
        _this.find("input[type='submit']").removeAttr("disabled");
        beetils.showFlash("success", "Successfully updated your commitment. Refreshing your graph...", true);
        beetils.showInfinibee(data.slug);
        beetils.pollUntilBeebrainUpdates("me", data.slug, function(gdata) {
          beetils.showFlash("success", "Updated your graph");
          updateWithGoalData(gdata);
          $(".refresh-stats").show();
        }, null)
        $("input.undo_road").removeAttr("disabled");
      },
      error: function(data) {
        $(_this).find("input[type='submit']").removeAttr("disabled")
        beetils.showFlash("error", "Couldn't update the graph: " + JSON.parse(data.responseText).error)
      },
    })
  })

  var STR_N = function(v){v=v+""; return v === ""? null : v};
  var NUM_N = function(v){
    var t;
    if (typeof(v) === "number") { return v; }
    v = v + "";
    t = Number(v);
    if (v !== "" && !isNaN(t)) {
      return t;
    } else {
      return null;
    }
  };
  var dateStampOfNow = function(){
    var now = new moment();
    return now.format("YYYY-MM-DD");
  };

  var H_empty_row = function() {
    return $('<div class="row-wrapper"><input type="text" class="matrix endtime"><input type="text" class="matrix value"><input type="text" class="matrix rate"><a class="remove-matrix-row clickable"><div class="octicon octicon-diff-removed"></div></a><a class="insert-matrix-row clickable"><div class="octicon octicon-diff-added"></div></a></div>')
}

var makeRoadString = function(roadEditor) {
    return JSON.stringify($(roadEditor).find(".row-wrapper").toArray().map(function(v) {
        return [STR_N($(".endtime", v).val()), NUM_N($(".value", v).val()), NUM_N($(".rate", v).val())]
    }))
}

var beemMatrix_catRows = function(roadEditor) {
  $(roadEditor).find("#goal_road_string").val(makeRoadString(roadEditor))
};

function initDatepicker($input) {
  if (!$input.data('datepicker-initialized')) {
    var dateVal = $input.val();
      $input.b_datepicker()
            .datepicker("setDate", dateVal || new Date())
            .data('datepicker-initialized', true);
  }
}
function bindRoadEditor() {
    // Use event delegation instead of binding to each element
    $(".road-editor").each(function(i, editor) {
        var $editor = $(editor);
        var namespace = ".roadEditor"+i
        
        // Remove any existing handlers to prevent duplicates
        $editor.off(namespace);

        // Initialize datepicker only on focus
        $editor.on("focus"+namespace, '.matrix.endtime', function() {
          initDatepicker($(this));
        });
        
        // Handle all matrix input changes through event delegation
        $editor.on("change"+namespace, '.matrix', function() {
            beemMatrix_catRows(editor);
        });
        
        // Handle add row button
        $editor.on("click"+namespace, '.add-matrix-row', function() {
            var $newRow = H_empty_row();
            $newRow.find('.endtime').val(dateStampOfNow());
            $editor.find(".rows").append($newRow);
            beemMatrix_catRows(editor);
        });
        
        // Handle insert row button
        $editor.on("click"+namespace, '.insert-matrix-row', function() {
            var $currentRow = $(this).closest('.row-wrapper');
            var $newRow = H_empty_row();
            $currentRow.after($newRow);
            
            // Get the date from the current row
            var currentDate = $currentRow.find('.matrix.endtime').val();
            $newRow.find('.matrix.endtime').val(currentDate);
                  
            beemMatrix_catRows(editor);
        });
        
        // Handle remove row button
        $editor.on("click"+namespace, '.remove-matrix-row', function() {
            $(this).closest('.row-wrapper').remove();
            beemMatrix_catRows(editor);
        });
    });
}

// Initial binding
bindRoadEditor();

  //-- end road editor --//

  // bind goal title form elements
  $("form#goal-title .goal-title-label").click(function () {
    $(this).hide();
    $("form#goal-title input[name='title']").show();
    $("form#goal-title input[name='title']").focus();
  })

  function saveGoalTitle(title) {
    var params = $.extend(beetils.tokenParams(), {
      "_method": "PUT",
      "title": title
    })
    $.ajax({
      type: "POST",
      url: "/api/v1/users/me/goals/" + _slug + ".json",
      data: params,
      success: function(data) {
        beetils.showFlash("success", "Updated your goal description");
        $("form#goal-title input[name='title']").unbind("blur");
        $("form #goal_title").val(title);
        bindTitleBlur();
      }, error: function(data) {
        beetils.showFlash("error", "Something went wrong when trying to update your goal description. Refresh the page and try again?");
      }
    });
  }

  function bindTitleBlur() {
    $("form#goal-title input[name='title']").blur(function() {
      var title = $(this).val();
      if ($(this).val().length === 0 && $(this).data("original-title").length === 0) { return; }
      $(this).data("original-title", $(this).val());
      if ($(this).val().length != 0) {
        $(this).hide();
        $("span#goal-title-text").text(title);
        $("form#goal-title .goal-title-label").show();
      }
      saveGoalTitle(title);
    });
  }

  bindTitleBlur();

  $("form#goal-title").submit(function(e) {
    e.preventDefault();
    $(this).find("input[name='title']").blur()
    return false;
  });
  // end goal title form

  // advanced entry form validation
  function advancedEntryErrors(form) {
    var datapoints = $.trim($(form).find("#datapoints_text").val());
    var text = $("<p>"+datapoints+"</p>").text().split("\n");
    var errors = text.filter(function(dtext) {
      return ($.trim(dtext) === "") ? false : !beetils.validDatapointText(dtext);
    });
    return errors;
  }

  $("form.oldbee.datapoint_new").submit(function() {
    beetils.hideFlash();
    var errors = advancedEntryErrors(this);
    // check the datapoints text:
    let dptInput = $(this).find("#datapoints_text")
    if ($.trim(dptInput.val()) === "") {
      dptInput.val("");
      setTimeout(function() {
        $(this).find("input:submit").removeAttr("disabled")
      }, 500);
      return false;
    }
    else if (errors.length === 0) {
      dptInput.removeClass("error")
      return true;
    } else {
      dptInput.addClass("error")
      beetils.showFlash("error", "Bad format: " + errors.join() + ". Correct format: date value \"optional comment\", eg  24 1 \"1 on the 24th\"");
      setTimeout(function() {
        $(this).find("input:submit").removeAttr("disabled")
      }, 500);
      return false;
    }
  });

  $("form.oldbee.datapoint_new #datapoints_text").on("keyup", function() {
    let this_area = $(this)
    let errors = advancedEntryErrors(this_area.closest('form'));
    if (errors.length === 0) {
      this_area.removeClass("error")
      //$("form.oldbee.datapoint_new #datapoints_text").removeClass("error");
    } else {
      this_area.addClass("error")
      //$("form.oldbee.datapoint_new #datapoints_text").addClass("error");
    }
  });
  // end advanced entry form validation

  // UNCLE BUTTON
  // the uncle button makes a call to the uncleme api goal endpoint
  $("#uncleme").click(function(){
    let _this = $(this)
    _this.prop("disabled",true)
    beetils.showInfinibee(_slug);
    $.ajax({
      type: "POST",
      url: "/api/v1/users/me/goals/"+_slug+"/uncleme.json",
      data: beetils.tokenParams(),
      success: function(data) {
        beetils.pollUntilBeebrainUpdates(_user, _slug, function(data) {
          updateWithGoalData(data)
        }, function(data) {
          console.log("bbrain polling error", data)
          beetils.hideInfinibee(_slug)
          _this.prop("disabled",false)
          beetils.showFlash("error", data.errors)
        })
      },
      error: function(error) {
        // should never see this #famouslastwords
        beetils.hideInfinibee(_slug);
        _this.prop("disabled",false)
        beetils.showFlash("error", "Something went wrong? Contact support@beeminder.com for help");
        console.log("ajax error callback", error)
      }
    })
  });

  // TODO: DRY THIS UP. IN BOTH DASHBOARD AND HERE
  // bind archive check 
  $(".insta-archive-check").click(function(e) {
    e.preventDefault();
    // first check if there's a charge pending
    $.ajax({
      type: "GET",
      url: "/api/v1/users/me/goals/"+_slug+".json",
      data: beetils.tokenParams(),
      success: function(data) {
        // set the slug data on the insta-archive button to be this goal's
        $(".insta-archive").data("slug", _slug);
        if (data.contract?.pending_amount > 0) {
        // launch the modal explaining the charge and with cancel option
          $("#insta-archive-modal .modal-header .amount").text("$"+data.contract.pending_amount)
          $("#insta-archive-modal").modal("show")
        } else {
          $(".insta-archive").click()
        }
      },
      error: function(error) {
        // should never see this #famouslastwords
        beetils.showFlash("error", "Something went wrong? Contact support@beeminder.com for help");
      }
    })
  });
  // bind archive button
  $(".insta-archive").click(function(e) {
    e.preventDefault();
    if ($(this).hasClass("disabled")) {
      return false;
    } else {
      $(this).addClass("disabled")
    }
    var params = $.extend(beetils.tokenParams(), {
      "_method": "PUT",
      "instaarchive": true
    });
    var __this = $(this)
    $.ajax({
      type: "POST",
      url: "/api/v1/users/me/goals/" + _slug + ".json",
      data: params,
      success: function(data) {
        if (data.contract && data.contract.pending_at) {
          msg = "FYI, this goal still has a $" +
          parseInt(data.contract.pending_amount) +
          " charge pending. Email support@beeminder.com if it's not legit."
          beetils.showFlash("success", msg)
          setTimeout(function() {
            window.location.reload()
          }, 2000)
        } else {
          window.location.reload()
        }
      },
      error: function(error) {
        // should never see this #famouslastwords
        __this.removeClass("disabled")
        beetils.showFlash("error", "Could not archive this goal &mdash; perhaps it's still in progress?");
      }
    });
  });
  // end bind archive button

  // bind delete button
  $(".insta-delete").click(function(e) {
    e.preventDefault();
    if ($(this).hasClass("disabled")) {
      return false;
    }
    if (!confirm("This permanently deletes the goal (and all its data). There's no undo! Are you sure?")) {
      return;
    }
    var params = $.extend(beetils.tokenParams(), {
      "_method": "PUT",
      "instadelete": true,
      "slug": _slug
    });
    $.ajax({
      type: "POST",
      url: "/api/v1/users/me/goals/" + _slug + ".json",
      data: params,
      success: function(data) {
        beetils.showFlash("success", "Deleted your goal. Redirecting...");
        setTimeout(function() {
          window.location.href = "/" + beemApi.currentUsername
        }, 2000)
      },
      error: function(error) {
        alert("There was a problem deleting your goal");
      }
    });
  });
  // end bind delete button

    function fancifySafesumTooltip()
    {
        $(".doom-modifier").tooltip();
        if (_user === "adamwolf" || _user === "d")
        {
            $(".doom-modifier").tooltip('show');
        }
    }

    fancifySafesumTooltip();
  
window.goaltils = {}
goaltils.updateWithGoalData = updateWithGoalData
});
